import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { ProductCard } from '@gemini/brand/el/ui/organisms/product-card';
import { CartError } from '@gemini/shared/services/cart/cart';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { MaxItemError } from '@gemini/shared/ui/molecules/product-cta';
import { ITabbedBlockProps, ITabItem } from './interfaces';
import tabbedBlockStyles from './TabbedBlock.module.scss';

const navButtonClass =
  'w-8 md:w-[45px] h-[100px] flex items-center justify-center bg-[#A4A4AB]/[.25] ';

export default function TabbedBlockComponent(props: ITabbedBlockProps) {
  const { data, productData, isDesktop } = props;
  const { header, subHeader, ...rest } = data;

  const tabs = useMemo(
    () => rest.tabs || (Object.values(rest) as ITabItem[]),
    [rest]
  );
  const tab = tabs[0];

  const templates = tab.blockReference.data.templates;
  const { config } = templates && templates[0].data;
  const {
    hideProductPrice,
    mppDefaultCtaOption,
    showSubDisplayName,
    carouselSettings
  } = config;

  const isMobile = !isDesktop;
  const showCta = mppDefaultCtaOption !== 'hide_cta';
  const [items, setItems] = useState(() => [...productData]);
  const [error, setError] = useState<CartError>();
  const largeDeviceSettings = { ...carouselSettings };
  const smallMobileBreakopint = 640;
  const smallMobilSetting = largeDeviceSettings.responsive.find(
    (setting) => setting.breakpoint === smallMobileBreakopint
  );

  if (!smallMobilSetting) {
    largeDeviceSettings.responsive.push({
      breakpoint: smallMobileBreakopint,
      settings: { ...carouselSettings.responsive[0].settings }
    });
  }
  largeDeviceSettings.responsive[0].settings.slidesToShow = 3;

  const cSettings = isDesktop
    ? largeDeviceSettings
    : {
        ...carouselSettings,
        slidesToScroll: carouselSettings.responsive[0].settings.slidesToScroll,
        slidesToShow: carouselSettings.responsive[0].settings.slidesToShow
      };

  useEffect(() => {
    setItems([...productData]);
  }, [productData]);

  const {
    elc_general: { next: _nextLabel, previous: _prevLabel }
  } = useTranslations();

  const nextLabel = _nextLabel || 'Next';
  const prevLabel = _prevLabel || 'Previous';

  const deferredItems = useDeferredValue(items);

  const settings = {
    ...(cSettings || {}),
    speed: 300,
    cssEase: 'linear',
    prevArrow: (
      <button
        className={`${navButtonClass} slider-arrow-wrapper lg:ml-[-2.25rem] nav-icons_left`}
        aria-label={prevLabel}
      >
        <Icon name="ArrowLeft" width="34" height="34" />
      </button>
    ),
    nextArrow: (
      <button
        className={`${navButtonClass} lg:mr-[-2.25rem] nav-icons_right`}
        aria-label={nextLabel}
      >
        <Icon name="ArrowRight" width="34" height="34" />
      </button>
    )
  };

  return (
    <>
      <MaxItemError error={error} onClose={() => setError(undefined)} />
      <div className="mx-auto max-w-[1150px] md:mt-[35px] mb-[12.5vw] md:mb-[20px]">
        <h3
          className="mx-0 mb-5px mt-[35px] text-center font-optimadisplaylight text-40px md:text-50px leading-[40px] lg:leading-none tracking-[0.75px] md:tracking-[-1px]"
          dangerouslySetInnerHTML={{ __html: header.data || '' }}
        />
        <div
          className="m-0 text-center leading-none font-akzidenzgrotesk tracking-[0.8px] text-[13px] mb-[62px] lg:mb-[38.5px]"
          dangerouslySetInnerHTML={{ __html: subHeader.data || '' }}
        />

        <div
          className={`w-full ${tabbedBlockStyles.tabbedBlock} overflow-hidden md:overflow-visible tabbed-block`}
        >
          <Slider {...settings}>
            {deferredItems?.map((item, index) => {
              return (
                <ProductCard
                  key={`ProductCard-${item.productId}`}
                  product={item}
                  positionIndex={index + 1}
                  showCta={showCta}
                  showPrice={!hideProductPrice}
                  showProductRating={true}
                  showTitle={true}
                  showSubtitle={showSubDisplayName}
                  imagePriority={index < 3}
                  isMobile={isMobile}
                  isDesktop={isDesktop}
                  productImageCarousel={false}
                  showShadeNavButtons={true}
                  onError={setError}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
}
