import { ITabbedBlockProps } from './interfaces';
import TabbedBlockComponent from './TabbedBlockComponent';

export interface IMultiUseHomepageHeroProps extends ITabbedBlockProps {
  dataMobile: ITabbedBlockProps['data'] | null;
}

export function TabbedBlock(props: ITabbedBlockProps) {
  return (
    <>
      {props.dataMobile && (
        <div className="block md:hidden">
          <TabbedBlockComponent
            data={props.dataMobile}
            productData={props.productData}
          />
        </div>
      )}
      <div className={`${props.dataMobile ? 'hidden md:block' : ''}`}>
        <TabbedBlockComponent
          data={props.data}
          productData={props.productData}
          isDesktop
        />
      </div>
    </>
  );
}

export default TabbedBlock;
