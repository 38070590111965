import { useCallback, useMemo } from 'react';
import { Fetcher } from '@gemini/shared/ui/utils/fetcher';
import { useProductGridData } from '@gemini/shared/ui/utils/products';
import { useGetProducts } from '@gemini/shared/ui/utils/swr';
import { getLogger } from '@gemini/shared/utils/logger';
import {
  ITabbedBlockProps,
  ITabbedBlockTemplateType,
  ITabItem
} from './interfaces';
import TabbedBlock from './TabbedBlock';

export const TabbedBlockWrapper = (
  props: Omit<ITabbedBlockProps, 'productData'>
) => {
  const { data } = props;
  const { header, subHeader, ...rest } = data;
  const tabs = useMemo(
    () => rest.tabs || (Object.values(rest) as ITabItem[]),
    [rest]
  );
  const tab = tabs[0];
  const { templates } = tab.blockReference.data;
  const {
    data: { config }
  }: ITabbedBlockTemplateType = templates[0];

  const { pendingIds, productData, useUpdateProducts } = useProductGridData(
    config.items
  );

  const getSDProducts = useCallback(
    () => useGetProducts(pendingIds),
    [pendingIds]
  );

  return (
    <Fetcher
      useLoader={getSDProducts}
      onLoad={useUpdateProducts}
      onError={getLogger<false>().logError}
      renderChildren={pendingIds.length === 0}
      renderOnLoad
    >
      <TabbedBlock {...props} productData={productData} data={data} />
    </Fetcher>
  );
};
